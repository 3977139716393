/* * @Author: liuzhixiang * @Date: 2021-08-05 15:58:23 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-08-25 14:23:18 */
<template>
  <div class="checkRecord" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="考勤签到"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="top">
      <div @click="show = true">
        <span>{{ StartTime === "" ? "选择开始时间" : StartTime }}</span>
        <i class="el-icon-date"></i>
      </div>
      <div class="icon_right">
        <img src="../../../assets/ShopImg/icon_rig.png" alt="" />
      </div>
      <div @click="show = true">
        <span>{{ EndTime === "" ? "选择结束时间" : EndTime }}</span>
        <i class="el-icon-date"></i>
      </div>
    </div>
    <div class="cont" v-if="list.length > 0">
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        v-for="(li, i) in list"
        :key="i"
      >
        <el-col :span="16">
          <div class="icon_calender"></div>
          <div class="til">
            <p>{{ li.CourseName }}</p>
            <p>已签到次数：{{ li.SignCount }}次</p>
          </div>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" round size="mini" @click="handleDetails(li)"
            >查看详情</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div v-if="list.length <= 0">
      <no-data></no-data>
    </div>
    <van-calendar
      v-model="show"
      :min-date="minDate"
      type="range"
      color="#1989fa"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { studentQueryStudentCoursePlanSignList } from "@/api/personal";
import noData from "@/components/noData";
export default {
  data() {
    return {
      route: {},
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      StartTime: "",
      EndTime: "",
      show: false,
      minDate: new Date(2020, 0, 1),
      isWxApplets: true,
    };
  },
  components: {
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "考勤签到";
      this.isWxApplets = false;
    }
    this.route = this.$route.query;
  },
  mounted() {
    this.init();
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    handleDetails(obj) {
      let path = "/H5/checkRecordDetails";
      let query = {
        UserId: this.route.UserId,
        CourseId: obj.Id,
        StartTime: this.StartTime,
        EndTime: this.EndTime,
      };
      this.$router.push({ path, query });
    },
    // 日期选择
    onConfirm(date) {
      const [start, end] = date;
      this.StartTime = this.common.dataYMD(start);
      this.EndTime = this.common.dataYMD(end);
      this.show = false;
      this.init();
    },
    //
    async init() {
      let parm = {
        studentId: this.route.UserId,
        StartTime: this.StartTime,
        EndTime: this.EndTime,
      };
      const res = await studentQueryStudentCoursePlanSignList(parm);
      if (res.success === true) {
        this.list = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.checkRecord {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 15px;
    background: #ffffff;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      flex: 1;
      height: 30px;
      border-radius: 40px;
      border: 1px solid #b1b2b4;
      padding: 0 20px;
      font-size: 12px;
      color: #b1b2b4;
      &:nth-child(1) {
      }
      &:nth-child(2) {
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #ffffff;
        border-radius: 50%;
        margin: 0 20px;
        flex: 0 0 30px;
        border: none;
        padding: 0;
        // background: url("../../../assets/ShopImg/icon_rig.png");
        background: #f1c800;
        // background-size: 100%;
        // background-repeat: no-repeat;
        img {
          width: 25px;
          height: 25px;
        }
      }
      &:nth-child(3) {
      }
    }
  }
  .cont {
    margin-top: 10px;
    .el-row {
      padding: 10px 15px;
      border-bottom: 1px solid #f5f5f5;
      background-color: #fff;
      .el-col {
        &:nth-child(1) {
          display: flex;
          align-items: center;
          .icon_calender {
            width: 28px;
            height: 28px;
            background: url("../../../assets/images/calendar.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            margin-right: 15px;
            flex: 0 0 28px;
          }
          .til {
            width: 100%;
            p {
              &:nth-child(1) {
                font-size: 15px;
                font-weight: bold;
                line-height: 21px;
                color: #333333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              &:nth-child(2) {
                font-size: 12px;
                line-height: 20px;
                color: #b1b2b4;
                margin-top: 5px;
              }
            }
          }
        }
        &:nth-child(2) {
          text-align: right;
        }
        .el-button {
          background: #333333;
        }
      }
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
